import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo_single.png';
import '../../assets/css/MobileHomeNavbar.css';
import { ReactComponent as HamburgerIcon } from '../../assets/images/hamburger.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/cross-circle.svg';

export const MobileHomeNavbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className={`MobileNavbarContainer ${showMenu ? 'MobileOverlay' : ''}`}>
      <div><img className='MobileNavbarLogo' src={logo} alt="Logo" /></div>
      <div className={`MobileNavbarLinks ${showMenu ? 'MobileOverlayShow' : 'MobileOverlayHide'}`}>
        <Link to="https://rehabpsych.info" onClick={toggleMenu} className='MobileNavbarLink'>Home</Link>
      </div>
      {showMenu ? <div onClick={toggleMenu}><CloseIcon className='CloseButton' /></div> : <div className='MobileNavbarHamburger' onClick={toggleMenu}><HamburgerIcon className="hamburger-icon" /></div>}
    </div>
  );
};