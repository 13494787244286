import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo_single.png';
import '../../assets/css/DesktopHomeNavbar.css';

export const DesktopHomeNavbar = () => {
  const navigate = useNavigate();
  return (
    <div className='DesktopNavbarContainer'>
      <img className='NavbarLogo' src={logo} alt="Logo" />
      <div className='NavbarLinks'>
        <NavLink to="https://rehabpsych.info" className='NavbarLink'>Home</NavLink>
      </div >
      <div className='NavbarLinks'>
        {/* <NavLink to="/contact" className='NavbarLink white-btn'>Contact Us</NavLink> */}
      </div>
    </div >
  )
};
