import React, { useRef, useEffect } from 'react';
import 'dhtmlx-scheduler';
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler.css';
import { getTokenFromLocalStorage } from '../../../helpers';

const AppointmentSetter = ({ events, onEventAdded, cancelAppointment, handleMultipleAppointments }) => {
    const schedulerContainer = useRef(null);
    const schedulerInstance = useRef(null);

    useEffect(() => {
        if (sessionStorage.getItem('refreshScheduler') === 'true') {
            sessionStorage.removeItem('refreshScheduler');
            window.location.reload();
        } else {
            if (typeof window.scheduler !== 'undefined') {
                schedulerInstance.current = window.scheduler;
                configureScheduler();
                initScheduler();
            } else {
                console.log('Scheduler is not available on window object');
            }
        }
    }, [events]);

    const configureScheduler = () => {
        const scheduler = schedulerInstance.current;
        if (!scheduler) {
            console.log('Scheduler instance is null in configureScheduler');
            return;
        }

        scheduler.skin = 'terrace';
        scheduler.config.header = [
            'day',
            'week',
            'month',
            'date',
            'prev',
            'today',
            'next'
        ];

        scheduler.config.start_on_monday = false;
        scheduler.templates.event_class = function (start, end, event) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (start < today) {
                return "past-event";
            }
            return event.classname || "green";
        };

        scheduler.config.lightbox.sections = [
            { name: "description", height: 50, map_to: "text", type: "textarea" },
            { name: "time", height: 72, type: "time", map_to: "auto" }
        ];

        scheduler.templates.lightbox_header = function (start, end, event) {
            if (event.text !== "New event") {
                return "Edit Appointment";
            }
            else {
                return "New Appointment";
            }
        };

        const PatientNameExists = scheduler.config.lightbox.sections.some(section => section.name === "Patient Name");
        if (!PatientNameExists) {

            scheduler.config.lightbox.sections.unshift({
                name: "Schedule Multiple Appointments",
                height: 30,
                map_to: "multiple_appointments",
                type: "checkbox",
                options: [
                    { key: true, label: "Book Multiple Appointments" }
                ]
            });

            scheduler.config.lightbox.sections.push({
                name: "Patient Name",
                height: 30,
                map_to: "patientname",
                type: "textarea"
            });

            scheduler.config.lightbox.sections.push({
                name: "Reason for Visit",
                height: 30,
                map_to: "reasonforvisit",
                type: "select",
                options: [
                    { key: "", label: "" },
                    { key: "Mood Evaluation", label: "Mood Evaluation" },
                    { key: "Depression/Anxiety", label: "Depression/Anxiety" },
                    { key: "Adjustment Disorder", label: "Adjustment Disorder" },
                    { key: "Schizo/Pyschosis", label: "Schizo/Pyschosis" }
                ]
            });

            scheduler.config.lightbox.sections.push({
                name: "Room Number",
                height: 30,
                map_to: "roomnumber",
                type: "textarea"
            });

            scheduler.config.lightbox.sections.push({
                name: "Comments",
                height: 30,
                map_to: "comments",
                type: "textarea"
            });

            scheduler.config.lightbox.sections.push({
                name: "Scheduled By",
                height: 30,
                map_to: "staff_member_name",
                type: "textarea"
            });

            scheduler.config.lightbox.sections.push({
                name: "Email",
                height: 30,
                map_to: "staff_email",
                type: "textarea"
            });
        }

        scheduler.clearAll();
        scheduler.locale.labels.section_selectme = "Event Color";
        scheduler.plugins({
            editors: true
        });
        scheduler.config.first_hour = 6;
        scheduler.config.last_hour = 20;
        scheduler.config.time_step = 15;
        scheduler.config.hour_height = 100;
        scheduler.config.hour_size_px = 100;
        scheduler.config.details_on_dblclick = false;
        scheduler.attachEvent("onClick", function (id, e) {
            if (id) {
                scheduler.showLightbox(id);
            }
            return false; // Prevent default behavior
        });

        scheduler.attachEvent("onBeforeDrag", function (id, mode, e) {
            if (!id) {
                return true; // Allow drag-create on empty space
            }

            const event = scheduler.getEvent(id);
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (event.start_date < today) {
                return false; // Prevent dragging past events
            }
            return true; // Allow dragging future events
        });


        scheduler.createDataProcessor(function (entity, action, data, id) {

            switch (action) {
                case "create":
                    return scheduler.loading === false && addAppointmentSetter(data.text, data.start_date, data.end_date, null, data.classname);
                case "update":
                    return scheduler.loading === false && updateAppointmentSetter(data, id);
                case "delete":
                    return scheduler.loading === false && deleteAppointmentSetter(id);
                default:
                    break;
            }
        });

        scheduler.attachEvent("onLightbox", function (id) {

            try {
                const event = scheduler.getEvent(id);
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                const descriptionSection = scheduler.formSection("description");
                if (descriptionSection && descriptionSection.control) {
                    // Only set default value if it's empty or "New event"
                    if (!descriptionSection.control.value || descriptionSection.control.value === "New event") {
                        descriptionSection.control.value = "Available for appointments"
                    }
                }

                const reasonforvisitSection = scheduler.formSection("Reason for Visit");
                if (reasonforvisitSection && reasonforvisitSection.control) {
                    reasonforvisitSection.control.disabled = true;
                }
                const roomnumberSection = scheduler.formSection("Room Number");
                if (roomnumberSection && roomnumberSection.control) {
                    roomnumberSection.control.disabled = true;
                }
                const patientnameSection = scheduler.formSection("Patient Name");
                if (patientnameSection && patientnameSection.control) {
                    patientnameSection.control.disabled = true;
                }
                const commentsSection = scheduler.formSection("Comments");
                if (commentsSection && commentsSection.control) {
                    commentsSection.control.disabled = true;
                }
                const scheduledbySection = scheduler.formSection("Scheduled By");
                if (scheduledbySection && scheduledbySection.control) {
                    scheduledbySection.control.disabled = true;
                }
                const emailSection = scheduler.formSection("Email");
                if (emailSection && emailSection.control) {
                    emailSection.control.disabled = true;
                }

                const checkboxInput = document.querySelector('input[name="Schedule Multiple Appointments"]');
                if (checkboxInput) {
                    checkboxInput.addEventListener('change', (event) => {
                        if (event.target.checked) {
                            handleMultipleAppointments(true);
                            window.scheduler.endLightbox(false, window.scheduler.getLightbox());
                        }
                    });
                }

                const hideDeleteButton = () => {
                    const deleteButton = document.getElementsByClassName('dhx_delete_btn_set');;
                    deleteButton[0].style.display = 'none';
                }

                const hideButtons = () => {
                    hideDeleteButton();
                    const cancelButton = document.getElementsByClassName('dhx_cancel_btn_set');
                    const saveButton = document.getElementsByClassName('dhx_save_btn_set');
                    const pushRight = document.getElementsByClassName('dhx_cal_lcontrols_push_right');
                    cancelButton[0].style.display = 'none';
                    saveButton[0].style.display = 'none';
                    pushRight[0].style.display = 'none';
                };

                if (event.start_date < today) {
                    hideButtons();
                }
                if (event.classname === 'red') {
                    hideButtons();
                    const controlsContainer = document.querySelector('.dhx_cal_lcontrols');
                    if (controlsContainer && !document.querySelector('.cancel-appointment-btn')) {
                        const cancelButton = document.createElement('div');
                        cancelButton.className = 'dhx_btn_set dhx_right_btn_set cancel-appointment-btn bg-red';
                        cancelButton.innerHTML = '<div>Cancel Appointment</div>';
                        cancelButton.onclick = () => {
                            cancelAppointment(true, event);
                        };
                        controlsContainer.appendChild(cancelButton);
                    }
                    hideButtons();
                }

                const timeSelects = document.querySelectorAll(".dhx_lightbox_time_select");
                if (timeSelects) {
                    timeSelects.forEach((select) => {
                        Array.from(select.options).forEach((option) => {
                            const hour = parseInt(option.text.split(':')[0]);
                            if ((hour < 6 || hour >= 20) && option.text !== '20:00') {
                                option.remove();
                            }
                        });
                    });
                }
            } catch (error) {
                console.error('Error in onLightbox event:', error);
            }
        });

    }

    const addAppointmentSetter = async (text, start_date, end_date, id = null, classname = 'green') => {
        schedulerInstance.current.loading = true;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
            body: JSON.stringify({ text, start_date, end_date, id, classname, hospitalId: window.scheduler.hospitalId })
        };
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/appointment-setter', requestOptions);
            const data = await response.json();
            if (response.ok) {
                console.log(data);
                schedulerInstance.current.loading = false;
                onEventAdded(window.scheduler.hospitalId);
            } else {
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            schedulerInstance.current.loading = false;
        }
    };

    const updateAppointmentSetter = async (data, id) => {
        data.classname = data.classname || 'green';
        schedulerInstance.current.loading = true;
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
            body: JSON.stringify({ text: data.text, provider: data.provider, start_date: data.start_date, end_date: data.end_date, id, classname: data.classname, reasonforvisit: data.reasonforvisit, roomnumber: data.roomnumber, patientname: data.patientname, comments: data.comments, scheduledby_id: data.scheduledby_id, staff_email: data.staff_email, user_id: data.user_id, hospital: data.hospital, provider_email: data.provider_email })
        };
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/users/doc-appointment-setter`, requestOptions);
            const responseData = await response.json();
            if (response.ok) {
                console.log(responseData);
                schedulerInstance.current.loading = false;
                onEventAdded(window.scheduler.hospitalId);
            } else {
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            schedulerInstance.current.loading = false;
        }
    };

    const deleteAppointmentSetter = async (id) => {
        schedulerInstance.current.loading = true;
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            }
        };
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/users/appointment-setter/${id}`, requestOptions);
            const data = await response.json();
            if (response.ok) {
                console.log(data);
                schedulerInstance.current.loading = false;
                onEventAdded(window.scheduler.hospitalId);
            } else {
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            schedulerInstance.current.loading = false;
        }
    };

    const initScheduler = () => {
        const scheduler = schedulerInstance.current;
        if (!scheduler) {
            console.log('Scheduler instance is null in initScheduler');
            return;
        }

        scheduler.config.header = [
            'day',
            'week',
            'month',
            'date',
            'prev',
            'today',
            'next'
        ];

        scheduler.config.start_on_monday = false;
        scheduler.templates.event_class = function (start, end, event) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (start < today) {
                return "past-event";
            }
            return event.classname || "green";
        };
        scheduler.templates.event_text = function (start, end, event) {
            return event.text + '; ' + event.provider || "Default Text";
        };
        scheduler.templates.event_header = function (start, end, event) {
            return event.text + '; ' + event.provider || "Default Text";
        };

        scheduler.clearAll();
        scheduler.parse(events);
        scheduler.locale.labels.section_selectme = "Event Color";
        scheduler.plugins({
            editors: true
        });

        if (schedulerContainer.current) {
            scheduler.init(schedulerContainer.current, new Date(), "week");
        } else {
            console.log('Scheduler container ref is null');
        }

    }

    return (
        <div className="mb-10" ref={schedulerContainer} style={{ width: '100%', height: '100%' }} />
    );
};

export default AppointmentSetter;
