import React, { useEffect, useState } from 'react'
import Toast from '../reusable/Toast';
import styles from '../../assets/css/Patient.module.css'
import Diagnosis from '../reusable/Diagnosis';
import { convertToCalendarSpecificDate, ViewCalendarDate, getTokenFromLocalStorage, isValidDate, selectLocation, keepOnlyNumbers } from '../../helpers/index'
import Loader from '../reusable/Loader';

const PatientDetails = ({ patient, subMode, mode, selectedDiagnosisProps = [] }) => {

    let id;
    if (patient) {
        id = patient.id;
    }
    const [firstname, setFirstName] = useState(patient && patient.firstname ? patient.firstname : null)
    const [lastname, setLastName] = useState(patient && patient.lastname ? patient.lastname : null)
    const [middlename, setMiddleName] = useState(patient && patient.middlename ? patient.middlename : null)
    const [age, setAge] = useState(patient && patient.age ? keepOnlyNumbers(patient.age) : null)
    const [gender, setGender] = useState(patient && patient.gender ? patient.gender.toLowerCase() : null)
    const [dateofbirth, setDob] = useState(patient && patient.dateofbirth ? convertToCalendarSpecificDate(patient.dateofbirth) : null)
    const [room, setRoom] = useState(patient && patient.room ? patient.room : null)
    const [location, setLocation] = useState(patient && patient.location ? patient.location : null)
    const [admitdate, setAdmitDate] = useState(patient && patient.admitdate ? convertToCalendarSpecificDate(patient.admitdate) : null)
    const [dischargedate, setDischargeDate] = useState(patient && patient.dischargedate ? convertToCalendarSpecificDate(patient.dischargedate) : null)
    const [visittype, setVisitType] = useState(patient && patient.visittype ? patient.visittype : null)
    const [facesheetalias, setFacesheetAlias] = useState(patient && patient.facesheetalias ? patient.facesheetalias : null)
    const [status, setStatus] = useState(patient && patient.status && patient.status ? patient.status : 'active')
    const [loading, setLoading] = useState(false)
    const [selectedDiagnosis, setSelectedDiagnosis] = useState(selectedDiagnosisProps);
    const [addPatientToChargesPage, setAddPatientToChargesPage] = useState(true)

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');

    useEffect(() => {
        fetchPatientDiagnoses();
        if (location !== null) {
            selectLocation(location);
        }
    }, [])

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const fetchPatientDiagnoses = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/patient-diagnoses?patientId=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
        });
        if (response.ok) {
            const data = await response.json();
            setSelectedDiagnosis(data);
        } else {
            console.error('Error:', response.status);
        }
    }

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const handleSelectedDiagnosis = (diagArray) => {
        patient.selectedDiagnosis = diagArray;
        setSelectedDiagnosis(diagArray);
    };

    const submitForm = async () => {

        if (firstname === null || firstname === '') {
            showErrorToast('Please enter first name');
            return;
        }

        if (lastname === null || lastname === '') {
            showErrorToast('Please enter last name');
            return;
        }

        if (dateofbirth === null || dateofbirth === '') {
            showErrorToast('Please select date of birth');
            return;
        }

        if (admitdate === null || admitdate === '') {
            showErrorToast('Please select admit date');
            return;
        }

        if (visittype === null) {
            showErrorToast('Please select visit type');
            return;
        }

        if (location === null || location === '') {
            showErrorToast('Please select place of service');
            return;
        }

        // if (selectedDiagnosis.length === 0) {
        //     showErrorToast('Please select at least one diagnosis');
        //     return;
        // }

        const token = getTokenFromLocalStorage()

        if (id === '') {
            alert('Empty patient id');
            throw Error('Empty patient id');
        }

        const formData = new URLSearchParams();
        formData.append('id', id);
        if (firstname) { formData.append('firstname', firstname); }
        if (lastname) { formData.append('lastname', lastname); }
        if (middlename) { formData.append('middlename', middlename); }
        if (gender) { formData.append('gender', gender); }
        if (dateofbirth) { formData.append('dateofbirth', dateofbirth); }
        if (room) { formData.append('room', room); }
        if (location) { formData.append('location', location); }
        if (admitdate) { formData.append('admitdate', admitdate); }
        if (dischargedate) { formData.append('dischargedate', dischargedate); }
        if (visittype) { formData.append('visittype', visittype); }
        if (status) { formData.append('status', status); }
        if (age) { formData.append('age', age); }
        if (facesheetalias) { formData.append('facesheetalias', facesheetalias); }

        try {
            setLoading(true)
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/add-patient`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + token,
                },
                body: formData.toString(),
            });

            const data = response;
            if (data.toString().indexOf('Updated successfully' !== -1)) {
                setLoading(false);
                showSuccessToast('Patient updated successfully');
                await saveDiagnosis();
                if (addPatientToChargesPage) {
                    await addPatientIDToChargesPage();
                }
            } else {
                setLoading(false);
                showErrorToast('Failed to update patient');
            }
        } catch (error) {
            setLoading(false);
            showErrorToast('An error occurred while updating patient');
        }
    }

    const addPatientIDToChargesPage = async () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
            },
            body: JSON.stringify({ patientId: id })
        };

        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/users/charges-patients-list`, requestOptions);
            const response = await result.json();
            if (result.ok) {
                setTimeout(() => {
                    showSuccessToast('Patient added to charges page');
                }, 1500);
            }
            else {
                setTimeout(() => {
                    showErrorToast(response.message);
                }, 1500);
            }
        } catch (error) {
            showErrorToast(error.message);
        }
    }

    const handleDates = (date) => {
        if ((mode === 'view&edit' && subMode === 'edit') || (mode === 'add' && subMode === 'addMultiplePatients')) {
            return (convertToCalendarSpecificDate(date));
        }
        if ((mode === 'view&edit' && subMode === 'view') || (mode === 'add' && subMode === 'view')) {
            return (ViewCalendarDate(date));
        }
    }

    const saveDiagnosis = async () => {
        setLoading(true)

        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            };
            const requestOptions = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ patientId: id, selectedDiagnosis })
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/patient-diagnoses`, requestOptions);
            if (response.ok) {
                setLoading(false);
                if (selectedDiagnosis.length > 0) {
                    showSuccessToast('Diagnosis Saved!');
                }
            } else {
                setLoading(false)
                console.error('Error:', response.status);
                showErrorToast('Error saving diagnosis');
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
            showErrorToast('Error saving diagnosis');
        }

    }

    const handlePatientOnChange = (patient_key, value) => {

        if (patient_key === 'firstname') {
            setFirstName(value);
            patient.firstname = value;
        }

        if (patient_key === 'lastname') {
            setLastName(value);
            patient.lastname = value;
        }

        if (patient_key === 'middlename') {
            setMiddleName(value);
            patient.middlename = value;
        }

        if (patient_key === 'age') {
            value = keepOnlyNumbers(value);
            setAge(value);
            patient.age = value;
        }

        if (patient_key === 'dateofbirth') {
            setDob(value);
            value = handleDates(dateofbirth);
            patient.dob = value;
        }

        if (patient_key === 'gender') {
            setGender(value);
            patient.gender = value;
        }

        if (patient_key === 'room') {
            setRoom(value);
            patient.room = value;
        }

        if (patient_key === 'location') {
            setLocation(value);
            patient.location = value;
        }

        if (patient_key === 'admitdate') {
            setAdmitDate(value);
            value = handleDates(admitdate);
            patient.admitdate = value;
        }

        if (patient_key === 'dischargedate') {
            setDischargeDate(value);
            value = handleDates(dischargedate);
            patient.dischargedate = value;
        }

    };


    return (
        <div style={{ justifyContent: 'flex-start', backgroundColor: 'white', height: '100%', borderRadius: '40px' }} className={styles.content}>
            {showToast && <Toast message={toastMessage} type={toastType} />}
            <form onSubmit={submitForm} className={styles.subContent}>
                <center><h3 style={{ marginBottom: '3rem', marginTop: '3rem' }}>Patient Details</h3></center>
                <div className={styles.columnContainer}>
                    <div className={styles.inputWrapper}>
                        <label htmlFor="firstName">First Name<span className={styles.required}>*</span></label>
                        <input
                            id="firstName"
                            className={styles.inputField}
                            placeholder="First Name"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={firstname ? firstname.toString().charAt(0).toUpperCase() + firstname.toString().toLowerCase().slice(1) : ''}
                            onChange={(e) => handlePatientOnChange('firstname', e.target.value)} />

                        <label htmlFor="middleName">Middle Name</label>
                        <input
                            id="middleName"
                            className={styles.inputField}
                            placeholder="Middle Name"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            value={middlename ? middlename.toString().charAt(0).toUpperCase() + middlename.toString().toLowerCase().slice(1) : ''}
                            onChange={(e) => handlePatientOnChange('middlename', e.target.value)} />

                        <label htmlFor="dateOfBirth">Date of Birth<span className={styles.required}>*</span></label>
                        <input
                            id="dateOfBirth"
                            placeholder="Date of Birth"
                            type="date"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            className={styles.dateField}
                            required
                            value={dateofbirth && isValidDate(dateofbirth) ? handleDates(dateofbirth) : ''}
                            onChange={(e) => handlePatientOnChange('dateofbirth', e.target.value)} />

                        <label htmlFor="room">Room</label>
                        <input
                            id="room"
                            className={styles.inputField}
                            placeholder="room"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            value={room ? room : ''}
                            onChange={(e) => handlePatientOnChange('room', e.target.value)} />

                        <label htmlFor="admitDate">Admit Date<span className={styles.required}>*</span></label>
                        <input
                            id="admitDate"
                            className={styles.dateField}
                            placeholder="Admit Date"
                            type="date"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={admitdate && isValidDate(admitdate) ? handleDates(admitdate) : ''}
                            onChange={(e) => handlePatientOnChange('admitdate', e.target.value)} />
                    </div>

                    <div className={styles.inputWrapper}>
                        <label htmlFor="lastName">Last Name<span className={styles.required}>*</span></label>
                        <input
                            id="lastName"
                            className={styles.inputField}
                            placeholder="Last Name"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={lastname ? lastname.toString().charAt(0).toUpperCase() + lastname.toString().toLowerCase().slice(1) : ''}
                            onChange={(e) => handlePatientOnChange('lastname', e.target.value)} />

                        <label htmlFor="age">Age</label>
                        <input
                            id="age"
                            className={styles.inputField}
                            placeholder="age"
                            type="number"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            value={keepOnlyNumbers(age)}
                            onChange={(e) => handlePatientOnChange('age', e.target.value)} />

                        <label htmlFor="gender">Gender</label>
                        <select
                            id="gender"
                            style={{ border: 0, outline: 0 }}
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            className={styles.selectInputField}
                            value={gender ? gender : ''}
                            onChange={(e) => handlePatientOnChange('gender', e.target.value)}>
                            <option className={styles.option} value="">Select Gender</option>
                            <option className={styles.option} value="m" selected={gender === 'm' ? true : false}>Male</option>
                            <option className={styles.option} value="f" selected={gender === 'f' ? true : false}>Female</option>
                            <option className={styles.option} value="o" selected={gender === 'o' ? true : false}>Other</option>
                        </select>

                        <label htmlFor="placeOfService">Place of Service<span className={styles.required}>*</span></label>

                        <select
                            id="placeOfService"
                            className={[styles.inputField, styles.select].join(' ')}
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={location ? selectLocation(location) : ''}
                            onChange={(e) => handlePatientOnChange('location', e.target.value)}
                        >
                            <option value="">Select Place of Service</option>
                            <option value="Chippenham Hospital">Chippenham Hospital</option>
                            <option value="Encompass Health Rehabilitation Hospital of Petersburg">Encompass Health Rehabilitation Hospital of Petersburg</option>
                            <option value="Henrico Doctors Hospital">Henrico Doctors Hospital</option>
                            <option value="Johnston Willis Hospital">Johnston Willis Hospital</option>
                            <option value="Parham Doctors Hospital">Parham Doctors Hospital</option>
                            <option value="Southside Regional Medical Center">Southside Regional Medical Center</option>
                        </select>

                        <label htmlFor="dischargeDate">Discharge Date</label>
                        <input
                            id="dischargeDate"
                            className={styles.dateField}
                            placeholder="Discharge Date"
                            type="date"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            value={dischargedate && isValidDate(dischargedate) ? handleDates(dischargedate) : ''}
                            onChange={(e) => handlePatientOnChange('dischargedate', e.target.value)} />
                    </div>
                </div>
                <div className={styles.diagnosisContainer} style={{ marginBottom: '1.5rem', marginTop: '3rem' }}>
                    <Diagnosis subMode={subMode} mode={mode} DiagnosisArray={selectedDiagnosis} handleSelectedDiagnosis={handleSelectedDiagnosis} />
                </div>
                <center><h3 style={{ marginBottom: '1.5rem', marginTop: '3rem' }}>Visit Type<span className={styles.required}>*</span></h3></center>
                <div className={styles.rowContainer}>
                    <div style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '1px solid var(placeholder-grey)' }} onClick={() => { (mode === 'add' || subMode === 'edit') && setVisitType('inpatient') }} className={`${styles.inactiveButton} ${visittype === 'inpatient' ? styles.activeButton : ''}`}>Inpatient</div>
                    <div style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => { (mode === 'add' || subMode === 'edit') && setVisitType('consult') }} className={`${styles.inactiveButton} ${visittype === 'consult' ? styles.activeButton : ''}`}>Consult</div>
                </div>
                <center><h3 style={{ marginBottom: '1.5rem', marginTop: '3rem' }}>Status</h3></center>
                <div style={{ marginBottom: '20px' }} className={styles.rowContainer}>
                    <div style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '1px solid var(placeholder-grey)' }} onClick={() => { (mode === 'add' || subMode === 'edit') && setStatus('active') }} className={`${styles.inactiveButton} ${status === 'active' ? styles.activeButton : ''}`}>Active</div>
                    <div style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => { (mode === 'add' || subMode === 'edit') && setStatus('inactive') }} className={`${styles.inactiveButton} ${status === 'inactive' ? styles.activeButton : ''}`}>Inactive</div>
                </div>

                {
                    (mode === 'add' || subMode === 'edit') && (
                        <div className={styles.checkboxContainer}>
                            <input type="checkbox" checked={addPatientToChargesPage} onChange={() => setAddPatientToChargesPage(!addPatientToChargesPage)} />
                            <span>Add this patient to charge page</span>
                        </div>
                    )
                }

                {loading ?
                    <div style={{ margin: '3px' }}><Loader /></div>
                    :
                    (mode === 'add' || subMode === 'edit') &&
                    (
                        <div onClick={() => submitForm()} style={{ marginBottom: '1.5rem', marginTop: '1rem' }} className={styles.rectangleBtn}>
                            Save
                        </div>
                    )
                }
                <div style={{ paddingBottom: '100px', height: '1px', width: '1px' }}></div>

            </form>
        </div >
    )
}

export default PatientDetails
