import React, { useState, useEffect } from 'react';
import './StaffManager.css';
import { getTokenFromLocalStorage } from '../../../helpers';

const StaffManager = () => {
    const [staffMembers, setStaffMembers] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [editingId, setEditingId] = useState(null);

    useEffect(() => {
        fetchStaffMembers();
    }, []);

    const fetchStaffMembers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/staff-members`, {
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage(),
                },
            });
            const data = await response.json();
            setStaffMembers(data);
        } catch (error) {
            console.error('Error fetching staff members:', error);
        }
    };

    const handleAddOrEdit = async () => {
        const method = editingId ? 'PUT' : 'POST';
        const url = editingId ? `${process.env.REACT_APP_API_URL}/users/staff-members/${editingId}` : `${process.env.REACT_APP_API_URL}/users/staff-members`;
        try {
            await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage(),
                },
                body: JSON.stringify({ name, staff_email: email }),
            });
            setName('');
            setEmail('');
            setEditingId(null);
            fetchStaffMembers();
        } catch (error) {
            console.error('Error adding/editing staff member:', error);
        }
    };

    const handleDelete = async (id) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this staff member?');
        if (!isConfirmed) {
            return;
        }

        try {
            await fetch(`${process.env.REACT_APP_API_URL}/users/staff-members/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage(),
                },
            });
            fetchStaffMembers();
        } catch (error) {
            console.error('Error deleting staff member:', error);
        }
    };

    const handleEdit = (member) => {
        setName(member.name);
        setEmail(member.staff_email);
        setEditingId(member.id);
    };

    return (
        <>
            <div className="staff-manager-container">
                <h1 className="staff-manager-header">Staff Manager</h1>
                <div className="staff-manager-form">
                    <input
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="staff-manager-input"
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="staff-manager-input"
                    />
                    <button onClick={handleAddOrEdit} className="staff-manager-button">
                        {editingId ? 'Edit' : 'Add'}
                    </button>
                </div>
                <div className="staff-manager-list">
                    {staffMembers.map((member) => (
                        <div key={member.id} className="staff-manager-item">
                            <span>{member.name} ({member.staff_email})</span>
                            <div style={{ display: 'flex' }}>
                                <button style={{ backgroundColor: '#007bff' }} onClick={() => handleEdit(member)} className="staff-manager-button">Edit</button>
                                <button onClick={() => handleDelete(member.id)} className="staff-manager-button">Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default StaffManager;