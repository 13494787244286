import React, { useState } from 'react';
import styles from '../../assets/css/Patient.module.css'
import { getTokenFromLocalStorage } from '../../helpers';
import axios from 'axios';
import Toast from '../reusable/Toast';
import AddMultiplePatients from './AddMultiplePatients';

const GetMultiplePatientsData = () => {
    const [loading, setLoading] = useState(false);
    const [facesheets, setFacesheets] = useState([]);
    const [visittype, setVisitType] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');
    const [patients, setPatients] = useState([]);

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

        const validFiles = files.filter(file => {
            if (!allowedExtensions.exec(file.name)) {
                showErrorToast(`${file.name} is not a valid file type`);
                return false;
            }
            return true;
        });

        setFacesheets(validFiles);
    };

    const uploadFacesheet = async () => {
        if (facesheets.length === 0) {
            showErrorToast('Please select facesheet files');
            return;
        }

        if (visittype === '') {
            showErrorToast('Please select a visit type');
            return;
        }

        try {
            setLoading(true);

            const uploadPromises = facesheets.map(file => {
                const formData = new FormData();
                formData.append('facesheet', file);
                formData.append('visittype', visittype);

                return axios.post(`${process.env.REACT_APP_API_URL}/users/get-facesheet-data`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                    }
                });
            });

            const results = await Promise.all(uploadPromises);
            const patientsArray = [];
            results.forEach(result => {
                const patient = result.data;
                patientsArray.push(patient);
            });
            setPatients(patientsArray);
            showSuccessToast('All facesheets uploaded successfully');
            setFacesheets([]);

        } catch (error) {
            showErrorToast('Error uploading some facesheets');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.content}>
            {patients.length > 0 ? <AddMultiplePatients patients={patients} /> : <div style={{ height: '100%', gap: '1.5rem', justifyContent: 'center' }} className={styles.subContent}>
                <h3>Upload Multiple Facesheets</h3>
                <div className={styles.rowContainer}>
                    <div style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '1px solid var(--placeholder-grey)' }} onClick={() => setVisitType('inpatient')} className={`${styles.inactiveButton} ${visittype === 'inpatient' ? styles.activeButton : ''}`}>Inpatient</div>
                    <div style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => setVisitType('consult')} className={`${styles.inactiveButton} ${visittype === 'consult' ? styles.activeButton : ''}`}>Consult</div>
                </div>
                <input type="file" multiple accept="image/*,application/pdf" onChange={handleFileChange} />
                {facesheets.length > 0 && (
                    <div>Selected files: {facesheets.length}</div>
                )}
                {loading ?
                    <div>Uploading facesheets...</div> :
                    <div style={{ width: '200px' }} onClick={uploadFacesheet} className="purple-btn">
                        Upload Facesheets
                    </div>
                }
            </div>}
            {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
        </div>
    );
};

export default GetMultiplePatientsData;