import React, { useEffect, useState } from 'react';
import styles from '../../assets/css/Patient.module.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import AddPatientMain from '../single/AddPatientMain';
import GetMultiplePatientsData from '../single/GetMultiplePatientsData';
import AddSinglePatient from '../single/AddSinglePatient';

const Patient = () => {
    const location = useLocation();
    const mode = location.state.mode;
    const [subMode, setSubMode] = useState('view');
    const navigate = useNavigate();
    const [addPatientChoice, setAddPatientChoice] = useState(null);

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            navigate('/signin');
        }
    }, [navigate]);

    const handleChoice = (choice) => {
        setAddPatientChoice(choice);
    }
    return (
        <div className={styles.mainContainer}>
            <div className="NavigationContainer">
                <div className="back-button-container">
                    <BackIcon onClick={() => navigate(-1)} className="back-button" />
                </div>
                {mode !== 'add' && <div className="toggle-container">
                    <div className="toggle" onClick={() => setSubMode(subMode === 'view' ? 'edit' : 'view')}>
                        <div className={`toggle-slider ${subMode === 'edit' ? 'active' : ''}`}></div>
                    </div>
                    <span>{subMode === 'view' ? 'View' : 'Edit'} Mode</span>
                </div>}
            </div>
            <div className={styles.whiteContainer}>
                {
                    mode === 'add' && addPatientChoice === null && <AddPatientMain handleChoice={handleChoice} />
                }
                {
                    addPatientChoice === 'multiple' && <GetMultiplePatientsData />
                }
                {
                    addPatientChoice === 'single' && <AddSinglePatient subMode={subMode} location={location} />
                }
                {
                    mode !== 'add' && <AddSinglePatient subMode={subMode} location={location} />
                }
            </div>
        </div>
    );
};

export default Patient;
