import { useRef } from 'react';
import { getTokenFromLocalStorage } from '../../helpers';
import { DesktopHomeNavbar } from './DesktopHomeNavbar';
import { MobileHomeNavbar } from './MobileHomeNavbar';
import { useLocation } from 'react-router-dom';

export const HomeNavbar = () => {
  const location = useLocation();
  const token = useRef(getTokenFromLocalStorage());
  if (location.pathname === '/patient-list' || location.pathname === '/patient' || location.pathname === '/charges' || location.pathname === '/profile' || location.pathname === '/utilities') {
    return null;
  }
  if (token.current !== null) {
    return null;
  }

  return (
    <>
      <DesktopHomeNavbar className="hide-on-mobile" />
      <MobileHomeNavbar />
    </>
  );
};