import React from 'react';
import styles from '../../assets/css/Patient.module.css'

const AddPatientMain = ({ handleChoice }) => {
    return (
        <div className={styles.content}>
            <div className={styles.subContent}>
                <>
                    <center><h3>Do you want to add single or multiple patients ?</h3></center>
                    <div className={styles.btnContainer}>
                        <div onClick={() => handleChoice('single')} className="purple-btn">Single</div>
                        <div onClick={() => handleChoice('multiple')} className="purple-btn">Multiple</div>
                    </div>
                </>
            </div>
        </div>
    );
};

export default AddPatientMain;