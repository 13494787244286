import React, { useEffect, useState } from 'react';
import Scheduler from '../reusable/Scheduler';
import { getTokenFromLocalStorage } from '../../helpers';
import Loader from './Loader';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import styles from '../../assets/css/Utilities.module.css'
import { useNavigate } from 'react-router-dom';

const EmployeeCalendar = () => {
    const navigation = useNavigate();
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    window.scheduler.loading = false;

    useEffect(() => {
        if (sessionStorage.getItem('refreshScheduler') === 'true') {
            sessionStorage.removeItem('refreshScheduler');
            window.location.reload();
        }
        else {
            fetchCalendarData();
        }
    }, []);

    const handleEventAdded = () => {
        fetchCalendarData();
    };

    const fetchCalendarData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/employee-calendar`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
            });
            if (response.ok) {
                const data = await response.json();
                setCalendarEvents(data);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading ? <Loader /> :
                <div className={styles.mainContainer}>
                    <div className={styles.navigationContainer}>
                        <BackIcon onClick={() => navigation(-1)} className="back-button" />
                        <h3 className={styles.heading}>Utilities</h3>
                    </div>
                    <div className='scheduler-container staff-calendar'>
                        <Scheduler events={calendarEvents} onEventAdded={handleEventAdded} />
                    </div>
                </div>
            }
        </>
    );
};

export default EmployeeCalendar;