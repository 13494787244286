import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Toast from '../reusable/Toast';
import styles from "../../assets/css/SignIn.module.css";
import { storeTokenInLocalStorage } from '../../helpers';
import { set } from 'date-fns';

const SignIn = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (accessToken) {
            navigate('/patient-list');
        }
    }, [navigate]);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const handleSubmitSignIn = (e) => {
        let errors = {};
        e.preventDefault();

        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is not valid';
        }
        // else if (!email.endsWith('@gmail.com')) {
        //     errors.email = 'Email must be a Gmail address';
        // }

        if (!password) {
            errors.password = 'Password is required';
        } else if (password.length < 8) {
            errors.password = 'Password must be at least 8 characters';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        setErrors({});
        setLoading(true);

        const headers = {
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ email, password })
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/signin`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.token) {
                    storeTokenInLocalStorage(data.token);
                    showSuccessToast('Signed in successfully');
                    setTimeout(() => {
                        navigate('/patient-list');
                    }, 1000);
                } else {
                    setLoading(false);
                    showErrorToast('Invalid email or password');
                    setShowToast(true);
                    setTimeout(() => {
                        navigate('/signin');
                    }, 3000);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                showErrorToast('Network error');
                setShowToast(true);
            });

    };

    return (
        <section className={styles.loginContainer}>
            <div className={styles.loginContent}>
                <form onSubmit={handleSubmitSignIn} className={styles.loginForm}>
                    <div className={styles.signInButtonWrapper}>
                        <div className={styles.signInText}>Sign In</div>
                        <div className={styles.signInDivider} />
                    </div>
                    {
                        !loading ?
                            <>
                                <div className={styles.inputWrapper}>
                                    <input
                                        className={styles.inputField}
                                        placeholder="Email"
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value.toLowerCase())} />
                                    {errors.email && <div className={styles.error}>{errors.email}</div>}

                                    <input
                                        className={styles.inputField}
                                        placeholder="Password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)} />
                                    {errors.password && <div className={styles.error}>{errors.password}</div>}
                                </div>
                                <button className={styles.submitButton}>Submit</button><div className={styles.signInBottomBox}>
                                    <p>Don't have an account? <Link className={styles.signInBottomLink} to="/signup">Sign Up</Link></p>
                                </div>
                            </>
                            : <div className={styles.loading}>Loading...</div>

                    }
                </form>
            </div>
            {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
        </section >
    );
};

export default SignIn;